export function UserIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title />
      <path
        fill="currentColor"
        d="M12,.5a5.7513,5.7513,0,0,0-5.45,6,5.7513,5.7513,0,0,0,5.45,6,5.7513,5.7513,0,0,0,5.45-6A5.7513,5.7513,0,0,0,12,.5Zm0,11a4.7549,4.7549,0,0,1-4.45-5A4.7549,4.7549,0,0,1,12,1.5a4.7549,4.7549,0,0,1,4.45,5A4.7549,4.7549,0,0,1,12,11.5Z"
      />
      <path
        fill="currentColor"
        d="M22.1494,15.293a20.8957,20.8957,0,0,0-6.3828-2.3291.5033.5033,0,0,0-.4819.1777,4.0823,4.0823,0,0,1-6.5708-.002.4959.4959,0,0,0-.481-.1777,20.4247,20.4247,0,0,0-6.3457,2.31A2.7278,2.7278,0,0,0,.5,17.6357V20.8a2.7031,2.7031,0,0,0,2.7,2.7H20.8a2.7031,2.7031,0,0,0,2.7-2.7v-3.166A2.6918,2.6918,0,0,0,22.1494,15.293ZM22.5,20.8a1.7021,1.7021,0,0,1-1.7,1.7H3.2a1.7021,1.7021,0,0,1-1.7-1.7V17.6357a1.7276,1.7276,0,0,1,.88-1.4951A19.4287,19.4287,0,0,1,8.1333,14,5.2046,5.2046,0,0,0,12,15.8a5.2037,5.2037,0,0,0,3.8647-1.7978,19.898,19.898,0,0,1,5.7906,2.16A1.69,1.69,0,0,1,22.5,17.6338Z"
      />
    </svg>
  )
}
