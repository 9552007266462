export function LockIcon() {
  return (
    <svg
      viewBox="0 0 24 24"
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title />
      <path
        fill="currentColor"
        d="M12,.5A11.5,11.5,0,1,0,23.5,12,11.5134,11.5134,0,0,0,12,.5Zm0,22A10.5,10.5,0,1,1,22.5,12,10.5115,10.5115,0,0,1,12,22.5Z"
      />
      <path
        fill="currentColor"
        d="M15,9.1H9a2.5023,2.5023,0,0,0-2.5,2.5V16A2.5023,2.5023,0,0,0,9,18.5h6A2.5023,2.5023,0,0,0,17.5,16V11.6A2.5023,2.5023,0,0,0,15,9.1ZM16.5,16A1.5017,1.5017,0,0,1,15,17.5H9A1.5017,1.5017,0,0,1,7.5,16V11.6A1.5017,1.5017,0,0,1,9,10.1h6a1.5017,1.5017,0,0,1,1.5,1.5Z"
      />
      <path
        fill="currentColor"
        d="M12.5,5.5h-1A2.5023,2.5023,0,0,0,9,8V9.6a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5V8A2.5023,2.5023,0,0,0,12.5,5.5ZM14,9.1H10V8a1.5017,1.5017,0,0,1,1.5-1.5h1A1.5017,1.5017,0,0,1,14,8Z"
      />
      <path
        fill="currentColor"
        d="M12,12.1a.5.5,0,0,0-.5.5V15a.5.5,0,0,0,1,0V12.6A.5.5,0,0,0,12,12.1Z"
      />
    </svg>
  )
}
