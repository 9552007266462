export function MessageIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title />
      <path
        fill="currentColor"
        d="M9.2456.6426A10.4883,10.4883,0,0,0,2.4224,17.0381L1.0146,19.8525a.9539.9539,0,0,0,1.1163,1.3438l3.7129-1.0606a10.3822,10.3822,0,0,0,5.1484,1.3653,10.8241,10.8241,0,0,0,1.5513-.1133A10.5033,10.5033,0,1,0,9.2456.6426ZM20.3774,12.5479A9.4884,9.4884,0,0,1,6.166,19.167a.4954.4954,0,0,0-.2554-.07.5064.5064,0,0,0-.1372.02L1.9092,20.3l1.5434-3.0869a.5.5,0,0,0-.0473-.5234,9.4951,9.4951,0,1,1,16.9721-4.1416Z"
      />
      <path
        fill="white"
        d="M22.93,16.0781a6.0124,6.0124,0,1,0-2.8768,6.08l2.35.6716a.2728.2728,0,0,0,.3189-.3843l-.926-1.852A5.9747,5.9747,0,0,0,22.93,16.0781Z"
      />
      <path
        fill="currentColor"
        d="M23.4253,16.0039a6.5025,6.5025,0,1,0-7.38,7.4268,6.6538,6.6538,0,0,0,.96.0693,6.4277,6.4277,0,0,0,3.1133-.8027l2.1489.6142a.7735.7735,0,0,0,.9023-1.09L22.3784,20.64A6.48,6.48,0,0,0,23.4253,16.0039Zm-2.0283,4.29a.5.5,0,0,0-.0474.5235l.6948,1.39-1.8535-.5293a.5019.5019,0,0,0-.3921.0508,5.44,5.44,0,0,1-3.61.7119,5.5,5.5,0,0,1,.809-10.94,5.85,5.85,0,0,1,.9263.0752,5.4934,5.4934,0,0,1,3.4727,8.7187Z"
      />
    </svg>
  )
}
