import { useContext } from 'react'
import { ThemeContext } from '@constants'

export function SmileIcon() {
  const themeContext = useContext(ThemeContext)

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 496 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={themeContext?.theme || 'currentColor'}
        d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm80 168c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm-160 0c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm80 256c-60.6 0-134.5-38.3-143.8-93.3-2-11.8 9.3-21.6 20.7-17.9C155.1 330.5 200 336 248 336s92.9-5.5 123.1-15.2c11.3-3.7 22.6 6.1 20.7 17.9-9.3 55-83.2 93.3-143.8 93.3z"
      />
    </svg>
  )
}
